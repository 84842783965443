import { external } from '@emobg/web-api-client';
import { createCustomStore } from '@emobg/web-utils';

export const ACTIONS = {
  putUpdateCompany: 'putUpdateCompany',
};

export const NAMESPACE = 'COMPANY_SETTINGS';

export const CompanySettingsModule = createCustomStore(({ runAsyncFlow }) => (
  {
    actions: {
      async [ACTIONS.putUpdateCompany]({ commit }, payload) {
        const { companyUuid, formCompanyData } = payload;
        await runAsyncFlow(commit, {
          request: external.userCompanyAccount.putUpdateCompany,
          params: [companyUuid, formCompanyData],
        });
      },
    },
  }
));
