import get from 'lodash/get';

export const isReplaceStateSupported = () => !!get(window, 'history.replaceState');

export const replaceState = () => {
  window.history.replaceState(null, null, ' ');
};

export const isLocationSupported = () => !!get(window, 'location');

export const locationRedirect = href => {
  window.location.href = href;
};
