import * as AccountModule from './Account/AccountModule';
import {
  IdentityCardStore,
  NAMESPACE as IdentityCardStoreNamespace,
} from './IdentityCard/IdentityCardStore';

export const NAMESPACE = 'UserModule';

export const ACTIONS = {
  ...AccountModule.ACTIONS,
};

export const GETTERS = {
  ...AccountModule.GETTERS,
};

export const store = {
  namespaced: true,
  modules: {
    [AccountModule.NAMESPACE]: AccountModule.store,
    [IdentityCardStoreNamespace]: IdentityCardStore,
  },
};
