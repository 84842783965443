import get from 'lodash/get';
import filter from 'lodash/filter';

import { createCustomStore, withScopes } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

import { PROMOTIONS } from '@/constants/promotions.const';

export const scopes = {
  userPromotions: 'userPromotions',
  code: 'code',
};

export const NAMESPACE = 'Promotions';

export const ACTIONS = {
  getCheckPromotion: 'getCheckPromotion',
  getUserPromotions: 'getUserPromotions',
  postCodeToUser: 'postCodeToUser',
};

export const GETTERS = {
  activePromotions: 'activePromotions',
  balance: 'balance',
  pastPromotions: 'pastPromotions',
};

export const Promotions = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, (
  {
    actions: {
      async [ACTIONS.getUserPromotions]({ commit }, userUuid) {
        await runAsyncFlow(commit, {
          request: external.promotions.getUserPromotions,
          params: [userUuid],
          scope: scopes.userPromotions,
        });
      },
      async [ACTIONS.getCheckPromotion]({ commit }, { code, operator }) {
        await runAsyncFlow(commit, {
          request: external.promotions.getCheckPromotion,
          params: [code, operator],
          scope: scopes.code,
        });
      },
      async [ACTIONS.postCodeToUser]({ commit, dispatch }, { userUuid, code }) {
        await runAsyncFlow(commit, {
          request: external.promotions.postCodeToUser,
          params: [userUuid, code],
          scope: scopes.code,
        }).then(() => dispatch('getUserPromotions', userUuid));
      },
    },
    getters: {
      [GETTERS.balance]: state => get(state, `${scopes.userPromotions}.data.balance`) || '0,00',
      [GETTERS.activePromotions]: state => filter(get(state, `${scopes.userPromotions}.data.promotions`), promotion => (promotion.statusCode === PROMOTIONS.active || promotion.statusCode === PROMOTIONS.pending)),
      [GETTERS.pastPromotions]: state => filter(get(state, `${scopes.userPromotions}.data.promotions`), ['statusCode', PROMOTIONS.expired]),
    },
  }
)));
