import * as ProfileCostAllocationsModule from './Profile/ProfileCostAllocationModule';
import * as CompanyCostAllocationsModule from './Company/CompanyCostAllocationModule';
import * as BookingCostAllocationModule from './Booking/BookingCostAllocationModule';

export const NAMESPACE = 'CostAllocationModule';

export const store = {
  namespaced: true,
  modules: {
    [ProfileCostAllocationsModule.NAMESPACE]: ProfileCostAllocationsModule.store,
    [CompanyCostAllocationsModule.NAMESPACE]: CompanyCostAllocationsModule.store,
    [CompanyCostAllocationsModule.NAMESPACE]: CompanyCostAllocationsModule.store,
    [BookingCostAllocationModule.NAMESPACE]: BookingCostAllocationModule.store,
  },
};
