import { getValue } from '@emobg/web-utils';
import toLower from 'lodash/toLower';
/* eslint-disable global-require */
import Vue from 'vue';

const local = {
  es: require('@/assets/translations/i18n/es.json'),
  en: require('@/assets/translations/i18n/en.json'),
  ca: require('@/assets/translations/i18n/ca.json'),
  pt: require('@/assets/translations/i18n/pt.json'),
  de: require('@/assets/translations/i18n/de.json'),
  da: require('@/assets/translations/i18n/da.json'),
  it: require('@/assets/translations/i18n/it.json'),
  fr: require('@/assets/translations/i18n/fr.json'),
  de_ch: require('@/assets/translations/i18n/de_CH.json'),
  fr_ch: require('@/assets/translations/i18n/fr_CH.json'),
  it_ch: require('@/assets/translations/i18n/it_CH.json'),
};

export default {
  setLanguages(languages, defaultLang) {
    languages.forEach(language => {
      // add translations directly to the application
      const translations = getValue(local, toLower(language), {});

      Vue.i18n.add(language, translations);
    });
    this.setDefaultLanguage(defaultLang);
  },
  setDefaultLanguage(defaultLang) {
    // set the start locale to use
    Vue.i18n.set(defaultLang);
  },
  setFallbackLanguage(locale) {
    Vue.i18n.fallback(locale);
  },
};
