import get from 'lodash/get';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

export const scopes = {
  tariffs: 'tariffs',
  availableTariffs: 'availableTariffs',
  defaultTariff: 'defaultTariff',
  postTariff: 'postTariff',
  cancelTariff: 'cancelTariff',
};

export const nameSpace = 'Tariffs';
export const Tariffs = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  actions: {
    async postDefaultTariff({ commit }, userUuid) {
      await runAsyncFlow(commit, {
        request: external.tariffs.postDefaultTariff,
        params: [userUuid],
        scope: scopes.defaultTariff,
      });
    },
    async postDefaultCompanyTariff({ commit }, companyUuid) {
      await runAsyncFlow(commit, {
        request: external.tariffs.postDefaultCompanyTariff,
        params: [companyUuid],
        scope: scopes.defaultTariff,
      });
    },
    async getTariffs({ commit }) {
      await runAsyncFlow(commit, {
        request: external.tariffs.getTariffs,
        params: ['v4'],
        scope: scopes.tariffs,
      });
    },
    async getAvailableUserTariff({ commit }, userUuid) {
      await runAsyncFlow(commit, {
        request: external.tariffs.getAvailableUserTariffs,
        params: [userUuid],
        scope: scopes.availableTariffs,
      });
    },
    async getAvailableCompanyTariffs({ commit }, companyTariffUuid) {
      await runAsyncFlow(commit, {
        request: external.tariffs.getAvailableCompanyTariffs,
        params: [companyTariffUuid],
        scope: scopes.availableTariffs,
      });
    },
    async postTariffToUser({ commit }, { tariffUuid, data }) {
      await runAsyncFlow(commit, {
        request: external.tariffs.postTariffToUser,
        params: [tariffUuid, data, 'v3'],
        scope: scopes.postTariff,
      });
    },
    async postMigratedTariffToUser({ commit }, { userUuid, tariffUuid, data }) {
      await runAsyncFlow(commit, {
        request: external.tariffs.postMigratedTariffToUser,
        params: [userUuid, tariffUuid, data],
        scope: scopes.postTariff,
      });
    },
    async postTariffToCompany({ commit }, { companyUuid, tariffUuid, data }) {
      await runAsyncFlow(commit, {
        request: external.tariffs.postTariffToCompany,
        params: [tariffUuid, companyUuid, data],
        scope: scopes.postTariff,
      });
    },
    async postMigratedTariffToCompany({ commit }, { companyUuid, tariffUuid, data }) {
      await runAsyncFlow(commit, {
        request: external.tariffs.postMigratedTariffToCompany,
        params: [companyUuid, tariffUuid, data],
        scope: scopes.postTariff,
      });
    },
    async patchCancelMigratedTariffSubscription({ commit }, { userUuid, tariffUuid }) {
      await runAsyncFlow(commit, {
        request: external.tariffs.patchCancelMigratedTariffSubscription,
        params: [userUuid, tariffUuid],
        scope: scopes.cancelTariff,
      });
    },
    async postCancelTariffSubscription({ commit }, { tariffUuid }) {
      await runAsyncFlow(commit, {
        request: external.tariffs.postCancelTariffSubscription,
        params: [tariffUuid],
        scope: scopes.cancelTariff,
      });
    },
    async postCancelCompanyTariffSubscription({ commit }, { tariffUuid, companyUuid }) {
      await runAsyncFlow(commit, {
        request: external.tariffs.postCancelCompanyTariffSubscription,
        params: [tariffUuid, companyUuid],
        scope: scopes.cancelTariff,
      });
    },
    async patchCancelMigratedCompanyTariffSubscription({ commit }, { tariffUuid, companyUuid }) {
      await runAsyncFlow(commit, {
        request: external.tariffs.patchCancelMigratedCompanyTariffSubscription,
        params: [companyUuid, tariffUuid],
        scope: scopes.cancelTariff,
      });
    },
  },
  getters: {
    tariffs(state) {
      return get(state, 'tariffs.data.data');
    },
  },
})));
