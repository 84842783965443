import { createCustomStore, withScopes } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

export const ACTIONS = {
  getUserIdentityCardValidation: 'getUserIdentityCardValidation',
  getIdentityTypesByCountry: 'getIdentityTypesByCountry',
  postUserIdentityCard: 'postUserIdentityCard',
};

export const NAMESPACE = 'UserIdentityCard';

export const SCOPES = {
  form: 'form',
  identityTypes: 'identityTypes',
  validation: 'validation',
};

export const IdentityCardStore = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, (
  {
    actions: {
      async [ACTIONS.getUserIdentityCardValidation]({ commit }, { userUuid, locationUuid }) {
        await runAsyncFlow(commit, {
          request: external.userCompanyUserIdentityCardService.getUserIdentityCardValidation,
          params: [userUuid, locationUuid],
          scope: SCOPES.validation,
        });
      },
      async [ACTIONS.getIdentityTypesByCountry]({ commit }, { countryUuid }) {
        await runAsyncFlow(commit, {
          request: external.userCompanyIdentityTypesService.getIdentityTypesByCountry,
          params: [countryUuid],
          scope: SCOPES.identityTypes,
        });
      },
      async [ACTIONS.postUserIdentityCard]({ commit }, { userUuid, data }) {
        await runAsyncFlow(commit, {
          request: external.userCompanyUserIdentityCardService.postUserIdentityCard,
          params: [userUuid, data],
          scope: SCOPES.form,
        });
      },
    },
  }
)));
