import { LOG_TYPE, logger } from '@emobg/web-utils';
import get from 'lodash/get';
import config from '@/config';

/**
 * Custom data for the payload to identify a user in Hotjar
 * @typedef {Object} HotjarPayload
 * @property {string} uuid
 * @property {string} app_version
 * @property {string} company_name
 * @property {string} company_uuid
 * @property {string} company_operator_uuid
 * @property {string} country
 * @property {string} default_city_uuid
 * @property {string} gender
 * @property {string} locale_code
 * @property {boolean} novice
 * @property {string} operator_name
 * @property {string} operator_uuid
 * @property {string} status
 */

/**
 * Creates a Hotjar payload from a user details endpoint
 * @param {Object} user  - Response from /from-idp endpoint
 * @param {Object} operator - { uuid, name }
 * @param {Object} languageService - LanguageService instance
 * @return {HotjarPayload}
 */
export const getHotjarPayload = ({ user = {}, operator = {}, languageService } = {}) => {
  let regionLocale = '';

  if (languageService) {
    const language = languageService.getLanguage();
    regionLocale = languageService.getLanguageCodeFromLocale(language);
  }

  const {
    uuid = '',
    country = '',
    gender = '',
    novice = '',
    status = '',
    locale_code: localeCode = regionLocale,
    default_city_uuid: defaultCityUuid = '',
  } = user;

  const {
    uuid: operatorUuid = '',
    name: operatorName = '',
  } = operator;

  const company = user.company || {};

  return {
    uuid,
    app_version: config.data.appVersion,
    company_name: company.commercial_name || '',
    company_uuid: company.uuid || '',
    company_operator_uuid: company.dedicated_fleet_cs_operator || '',
    country,
    default_city_uuid: defaultCityUuid,
    gender,
    locale_code: localeCode,
    novice: !!novice,
    operator_name: operatorName,
    operator_uuid: operatorUuid,
    status,
  };
};

/**
 * Identify user in the current Hotjar session
 * @param {HotjarPayload} hotjarPayload
 */
export const identifyHotjarUser = hotjarPayload => {
  if (!window.hj) {
    logger.message('User could not be identified in Hotjar', LOG_TYPE.warning);
    return;
  }

  const { uuid = '', ...params } = hotjarPayload;

  window.hj('identify', uuid, { ...params });
};

/**
 * Check if we should identify the user in Hotjar or not
 * @param {Object} user - Response from /from-idp endpoint
 * @return {boolean}
 */
export const shouldBeIdentifiedInHotjar = user => !get(user, 'company.owns_employee_data', true);
