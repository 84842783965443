import { external } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';

export const MUTATIONS = {
  setKeyCardBadgeList: 'setKeyCardBadgeList',
};

export const ACTIONS = {
  getEmployeePolicy: 'getEmployeePolicy',
  getKeyCardBadgeList: 'getKeyCardBadgeList',
  postInviteAnEmployee: 'postInviteAnEmployee',
  putEditLicenseExpirationDate: 'putEditLicenseExpirationDate',
  putToggleActiveEmployee: 'putToggleActiveEmployee',
  putCancelEmployeeBadge: 'putCancelEmployeeBadge',
  putAddEmployeeBadge: 'putAddEmployeeBadge',
  getEmployeeList: 'getEmployeeList',
};

export const SCOPES = {
  inviteAnEmployee: 'inviteAnEmployee',
  editLicenseExpirationDate: 'editLicenseExpirationDate',
  employeeList: 'employeeList',
  keyCardBadgeList: 'keyCardBadgeList',
  deactivateUser: 'deactivateUser',
  removeKeyCard: 'removeKeyCard',
  addKeyCard: 'addKeyCard',
};

export const NAMESPACE = 'USER_COMPANY_SETTINGS';

export const UserCompanySettingsModule = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, ({
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;
      state[scope].error = value;
    },
  },
  actions: {
    async [ACTIONS.getEmployeePolicy]({ commit }, { companyUuid }) {
      await runAsyncFlow(commit, {
        request: external.userCompany.getEmployeePolicy,
        params: [companyUuid],
      });
    },
    async [ACTIONS.getEmployeeList]({ commit }) {
      await runAsyncFlow(commit, {
        request: external.userCompany.getEmployeeList,
        scope: SCOPES.employeeList,
      });
    },
    async [ACTIONS.getKeyCardBadgeList]({ commit }) {
      await runAsyncFlow(commit, {
        request: external.badges.getEmployeeBadgeList,
        scope: SCOPES.keyCardBadgeList,
      });
    },
    async [ACTIONS.postInviteAnEmployee]({ commit }, { userUuid, emails }) {
      await runAsyncFlow(commit, {
        request: external.userCompanyEmployee.postInviteAnEmployee,
        params: [userUuid, emails],
        scope: SCOPES.inviteAnEmployee,
      });
    },
    async [ACTIONS.putEditLicenseExpirationDate]({ commit }, { userUuid, checkedParams }) {
      await runAsyncFlow(commit, {
        request: external.userCompany.putEditLicenseExpirationDate,
        params: [userUuid, checkedParams],
        scope: SCOPES.editLicenseExpirationDate,
      });
    },
    async [ACTIONS.putToggleActiveEmployee]({ commit }, { employeeUuid, data }) {
      await runAsyncFlow(commit, {
        request: external.userCompanyEmployee.putToggleActiveEmployee,
        params: [employeeUuid, data],
        scope: SCOPES.deactivateUser,
      });
    },
    async [ACTIONS.putCancelEmployeeBadge]({ commit }, { employeeUserUuid, badge }) {
      await runAsyncFlow(commit, {
        request: external.userCompany.putCancelEmployeeBadge,
        params: [employeeUserUuid, badge],
        scope: SCOPES.removeKeyCard,
      });
    },
    async [ACTIONS.putAddEmployeeBadge]({ commit }, { employeeUserUuid, badge }) {
      await runAsyncFlow(commit, {
        request: external.userCompany.putAddEmployeeBadge,
        params: [employeeUserUuid, badge],
        scope: SCOPES.addKeyCard,
      });
    },
  },
}
)));
