import size from 'lodash/size';
import pick from 'lodash/pick';
import values from 'lodash/values';
import { UTM_PARAMETERS } from '@/constants/utms';

export const pickUtmParameters = (utmParameters = {}) => pick(utmParameters, values(UTM_PARAMETERS));

export const shouldSendAttributedEvent = (utmParameters = {}) => {
  const requiredUtmParameters = [UTM_PARAMETERS.source, UTM_PARAMETERS.medium];
  const filteredUtmParameters = pick(utmParameters, requiredUtmParameters);

  return size(filteredUtmParameters) === size(requiredUtmParameters);
};
