import { mapGetters, mapMutations } from 'vuex';
import { NAMESPACE as ThemeNamespace } from './ThemeModule';

export const {
  currentFlavor,
  setFlavor,
} = {
  ...mapGetters(ThemeNamespace, [
    'currentFlavor',
  ]),
  ...mapMutations(ThemeNamespace, [
    'setFlavor',
  ]),
};
