import { mapActions, mapGetters } from 'vuex';
import * as ProfileModule from '@Profile/store';

export const {
  isPersonalProfileActive,
  isBusinessProfileActive,
  profileCollection,
  personalProfile,
  currentProfile,
  setCurrentProfile,
  selectProfile,
  canUseCostAllocation,
  getCurrentProfileUuid,
  getCurrentProfileType,
  getCurrentProfileCompanyUuid,
  hasOnlyBusinessProfile,
  channelsByType,
  updateNotificationChannel,
  refreshProfileInformation,
  getCurrentProfileAlias,
  hasBusinessProfile,
  getProfileByUUID,
  getSelectedProfile,
} = {
  ...mapGetters(ProfileModule.nameSpace, {
    profileCollection: ProfileModule.GETTERS.getProfileCollection,
    canUseCostAllocation: ProfileModule.GETTERS.canUseCostAllocation,
    personalProfile: ProfileModule.GETTERS.hasPersonalProfile,
    currentProfile: ProfileModule.GETTERS.getCurrentProfile,
    isPersonalProfileActive: ProfileModule.GETTERS.isPersonalProfileActive,
    isBusinessProfileActive: ProfileModule.GETTERS.isBusinessProfileActive,
    getCurrentProfileUuid: ProfileModule.GETTERS.getCurrentProfileUuid,
    getCurrentProfileType: ProfileModule.GETTERS.getCurrentProfileType,
    getCurrentProfileCompanyUuid: ProfileModule.GETTERS.getCurrentProfileCompanyUuid,
    hasOnlyBusinessProfile: ProfileModule.GETTERS.hasOnlyBusinessProfile,
    channelsByType: ProfileModule.GETTERS.channelsByType,
    getCurrentProfileAlias: ProfileModule.GETTERS.getCurrentProfileAlias,
    hasBusinessProfile: ProfileModule.GETTERS.hasBusinessProfile,
    getProfileByUUID: ProfileModule.GETTERS.getProfileByUUID,
    getSelectedProfile: ProfileModule.GETTERS.getSelectedProfile,
  }),

  ...mapActions(ProfileModule.nameSpace, {
    setCurrentProfile: ProfileModule.ACTIONS.setCurrentProfile,
    selectProfile: ProfileModule.ACTIONS.selectProfile,
    updateNotificationChannel: ProfileModule.ACTIONS.updateNotificationChannel,
    refreshProfileInformation: ProfileModule.ACTIONS.refreshProfileInformation,
  }),
};
