import CryptoJS from 'crypto-js';

const key = process.env.VUE_APP_ENCRYPT_ENVS_KEY;

export const decryptString = encryptedString => {
  const bytes = CryptoJS.AES.decrypt(encryptedString, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const decryptObject = encryptedObject => {
  const bytes = CryptoJS.AES.decrypt(encryptedObject, key);
  const noriginalText = bytes.toString(CryptoJS.enc.Utf8);
  return noriginalText && JSON.parse(noriginalText);
};
