import { INITIAL_STATUS as DEFAULT_INITIAL_STATUS, getValue } from '@emobg/web-utils';
import { allowedThemes } from '@/constants/themes';
import { isAllowedFlavor } from '@/utils/assetsRepository';

export const NAMESPACE = 'Theme';

export const INIT_STATE = () => ({
  data: {
    currentFlavor: allowedThemes.Ubeeqo,
  },
  status: { ...DEFAULT_INITIAL_STATUS },
});

export const getters = {
  currentFlavor(state) {
    return getValue(state, 'data.currentFlavor');
  },
};

export const mutations = {
  setFlavor(state, newFlavor) {
    state.data.currentFlavor = isAllowedFlavor(newFlavor) ? newFlavor : allowedThemes.Ubeeqo;
  },
};

export const ACTIONS = {};

const actions = {};

export const store = {
  namespaced: true,
  state: INIT_STATE,
  getters,
  mutations,
  actions,
};
