import { createCustomStore, withScopes } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';
import get from 'lodash/get';

export const scopes = {
  insurances: 'insurances',
  newInsurance: 'newInsurance',
  cancelRenewal: 'cancelRenewal',
};

export const nameSpace = 'Insurances';
export const Insurances = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, (
  {
    actions: {
      async getInsurances({ commit }, query) {
        await runAsyncFlow(commit, {
          request: external.insurances.getInsurances,
          params: [query],
          scope: scopes.insurances,
        });
      },
      async postNewInsurance({ commit }, { userUuid, insuranceUuid }) {
        await runAsyncFlow(commit, {
          request: external.insurances.postNewInsurance,
          params: [userUuid, insuranceUuid],
          scope: scopes.newInsurance,
        });
      },
      async deleteAutorenewInsurance({ commit }, { userUuid, insuranceUuid }) {
        await runAsyncFlow(commit, {
          request: external.insurances.deleteAutorenewInsurance,
          params: [userUuid, insuranceUuid],
          scope: scopes.cancelRenewal,
        });
      },
    },
    getters: {
      insurances(state) {
        return get(state, 'insurances.data');
      },
    },
  }
)));
