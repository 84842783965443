import { external } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import get from 'lodash/get';

export const NAMESPACE = 'ProfileCostAllocations';

export const ACTIONS = {
  fetchProfileCostAllocation: 'fetchProfileCostAllocation',
  updateProfileCostAllocation: 'updateProfileCostAllocation',
};

export const GETTERS = {
  getProfileCostAllocations: 'getProfileCostAllocations',
  isFetchProfileCostAllocationsLoading: 'isFetchProfileCostAllocationsLoading',
  isProfileCostAllocationsLoading: 'isProfileCostAllocationsLoading',
};

export const SCOPES = {
  profileCostAllocations: 'profileCostAllocations',
};

export const store = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, (
  {
    namespaced: true,
    getters: {
      [GETTERS.getProfileCostAllocations](state) {
        return get(state, `${SCOPES.profileCostAllocations}.data.data`);
      },
      [GETTERS.isFetchProfileCostAllocationsLoading](state) {
        return get(state, `${SCOPES.profileCostAllocations}.STATUS.LOADING`);
      },
      [GETTERS.isProfileCostAllocationsLoading](state) {
        return get(state, 'STATUS.LOADING');
      },
    },
    actions: {
      async [ACTIONS.fetchProfileCostAllocation]({ commit }, profileUuid) {
        await runAsyncFlow(commit, {
          request: external.companyCostAllocation.getUserProfileCostAllocations,
          params: [profileUuid],
          scope: SCOPES.profileCostAllocations,
        });
      },
      async [ACTIONS.updateProfileCostAllocation]({ commit }, { profileUuid, costAllocations }) {
        await runAsyncFlow(commit, {
          request: external.companyCostAllocation.postUserProfileCostAllocations,
          params: [profileUuid, costAllocations],
        });
      },
    },
  }
)));
