import get from 'lodash/get';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { external as Api } from '@emobg/web-api-client';

export const scopes = {
  paymentMethodPSD2: 'paymentMethodPSD2',
  paymentMethod: 'paymentMethod',
  threeDS2Auth: 'threeDS2Auth',
  identify: 'identify',
  storePaymentMethod: 'storePaymentMethod',
  paymentMethodList: 'paymentMethodList',
  companyPaymentMethodList: 'companyPaymentMethodList',
  defaultPaymentMethod: 'defaultPaymentMethod',
};

export const nameSpace = 'PaymentMethods';
export const PaymentMethods = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, (
  {
    actions: {

      async postPaymentMethodPSD2({ commit }, { userUuid, payload }) {
        await runAsyncFlow(commit, {
          request: Api.paymentsUser.postPaymentMethodPSD2,
          params: [userUuid, payload],
          scope: scopes.paymentMethodPSD2,
        });
      },

      async postCompanyPaymentMethod({ commit }, { companyUuid, payload }) {
        await runAsyncFlow(commit, {
          request: Api.paymentsCompany.postCompanyPaymentMethod,
          params: [companyUuid, payload],
          scope: scopes.paymentMethodPSD2,
        });
      },

      async getCompanyPaymentMethods({ commit }, { companyUuid, payload }) {
        await runAsyncFlow(commit, {
          request: Api.paymentsCompany.getCompanyPaymentMethods,
          params: [companyUuid, payload],
          scope: scopes.companyPaymentMethodList,
        });
      },

      async addPaymentMethod({ commit }, { userUuid, payload }) {
        await runAsyncFlow(commit, {
          request: Api.paymentsUser.postPaymentMethod,
          params: [userUuid, payload],
          scope: scopes.paymentMethod,
        });
      },

      async postAuthenticate3DS2Challenge({ commit }, { data, version = 'v4' }) {
        await runAsyncFlow(commit, {
          request: Api.paymentsUser.postAuthenticate3DS2Challenge,
          params: [data, version],
          scope: scopes.paymentMethodPSD2,
        });
      },

      async authenticate3DS2Redirect({ commit }, payload) {
        await runAsyncFlow(commit, {
          request: Api.paymentsUser.postAuthenticate3DS2Redirect,
          params: [payload],
          scope: scopes.threeDS2Auth,
        });
      },

      async getUserPaymentMethods({ commit }, userUuid) {
        await runAsyncFlow(commit, {
          request: Api.paymentsUser.getUserPaymentMethods,
          params: [userUuid],
          scope: scopes.paymentMethodList,
        });
      },

      async storePaymentMethod({ commit }, { customerType, paymentMethodUuid }) {
        await runAsyncFlow(commit, {
          request: Api.paymentsUser.getStorePaymentMethod,
          params: [customerType, paymentMethodUuid],
          scope: scopes.storePaymentMethod,
        });
      },

      async getDefaultPaymentMethod({ commit }, { data, version }) {
        await runAsyncFlow(commit, {
          request: Api.paymentsUser.getDefaultPaymentMethod,
          params: [data, version],
          scope: scopes.defaultPaymentMethod,
        });
      },
    },
    getters: {
      userPaymentMethods: state => get(state, 'paymentMethodList.data'),
      companyPaymentMethods: state => get(state, 'companyPaymentMethodList.data'),
      userDefaultPaymentMethod: state => get(state, 'defaultPaymentMethod.data'),
    },
  })));
