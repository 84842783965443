import get from 'lodash/get';
import { external } from '@emobg/web-api-client';
import {
  STORE_REQUEST_STATUS,
  createCustomStore,
  withScopes,
} from '@emobg/web-utils';

export const NAMESPACE = 'UserLegalDocuments';

const SCOPES = {
  fetching: 'fetching',
  updating: 'updating',
};

export const GETTERS = {
  missingLegalDocuments: 'missingLegalDocuments',
};

export const ACTIONS = {
  getMissingLegalDocuments: 'getMissingLegalDocuments',
  putUserLegalDocuments: 'putUserLegalDocuments',
};

export const store = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, {
  getters: {
    [GETTERS.missingLegalDocuments](state) {
      return get(state, `${SCOPES.fetching}.data.documents`);
    },
  },
  actions: {
    [ACTIONS.getMissingLegalDocuments]({ commit }, { operatorUuid }) {
      return runAsyncFlow(commit, {
        request: external.userCompanyUserDocuments.getMissingLegalDocuments,
        params: [operatorUuid],
        scope: SCOPES.fetching,
      });
    },

    async [ACTIONS.putUserLegalDocuments]({ commit, state }, { legalDocumentsUuid = [] }) {
      const payload = {
        document_uuids: legalDocumentsUuid,
      };

      await runAsyncFlow(commit, {
        request: external.userCompanyUserDocuments.putUserLegalDocuments,
        params: [payload],
        scope: SCOPES.updating,
      });

      const isLoaded = get(state, `${SCOPES.updating}.STATUS.${STORE_REQUEST_STATUS.loaded}`, true);

      return isLoaded ? {} : undefined;
    },
  },
}));
