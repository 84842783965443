import each from 'lodash/each';
import size from 'lodash/size';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import remove from 'lodash/remove';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import get from 'lodash/get';

import { INITIAL_STATUS as DEFAULT_INITIAL_STATUS, STORE_REQUEST_STATUS } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

export const NAMESPACE = 'VehicleUser';

export const INIT_STATE = () => ({
  data: [],
  status: { ...DEFAULT_INITIAL_STATUS },
  invalidDrivers: [],
});

export const GETTERS = {
  data: 'DATA',
  count: 'COUNT',
  isInvalidDriver: 'IS_INVALID_DRIVER',
  hasDriver: 'HAS_DRIVER',
};

export const getters = {
  [GETTERS.data](state) {
    return (filters = {}) => filter(state.data, filters);
  },

  [GETTERS.count](state, getter) {
    return (filters = {}) => size(get(getter, GETTERS.data, () => [])(filters));
  },

  [GETTERS.isInvalidDriver](state) {
    return email => !isNil(find(state.invalidDrivers, ['email', email]));
  },

  [GETTERS.hasDriver](state) {
    return email => !isNil(find(state.data, ['data', email]));
  },
};

export const MUTATIONS = {
  add: 'ADD',
  remove: 'REMOVE',
  removeAll: 'REMOVE_ALL',
  setStatus: 'SET_STATUS',
  setInvalidDrivers: 'SET_INVALID_DRIVERS',
};

export const mutations = {
  [MUTATIONS.add](state, { id, type, data }) {
    state.data = [...state.data, { id, type, data }];
  },

  [MUTATIONS.remove](state, payload) {
    const vehicleUsers = state.data;
    remove(vehicleUsers, payload);
    state.data = [...vehicleUsers];
  },

  [MUTATIONS.removeAll](state) {
    state.data = [];
    state.invalidDrivers = [];
  },

  [MUTATIONS.setStatus](state, payload) {
    if (has(state.status, payload)) {
      each(state.status, (value, key) => {
        state.status[key] = key === payload;
      });
    }
  },

  [MUTATIONS.setInvalidDrivers](state, payload) {
    if (isArray(payload)) {
      state.invalidDrivers = payload;
    }
  },
};

export const ACTIONS = {
  validateDrivers: 'VALIDATE_DRIVERS_EMAILS',
  updateDrivers: 'UPDATE_DRIVERS',
};

const actions = {
  async [ACTIONS.validateDrivers]({ commit }, { companyUuid, emails }) {
    commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loading);

    try {
      const validatedDrivers = await external.userCompanyAccount.postCheckCompanyEmails(companyUuid, { emails });

      const invalidDrivers = filter(validatedDrivers, ['valid', false]);

      commit(MUTATIONS.setInvalidDrivers, invalidDrivers);
      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loaded);

      return invalidDrivers;
    } catch (error) {
      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.error);
      throw error;
    }
  },

  async [ACTIONS.updateDrivers]({ commit }, { bookingUuid, emails }) {
    commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loading);

    try {
      await external.booking.postAdditionalDrivers(bookingUuid, { emails });

      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loaded);
    } catch (error) {
      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.error);
      throw error;
    }
  },
};

export const store = {
  namespaced: true,
  state: INIT_STATE,
  getters,
  mutations,
  actions,
};
