import pickBy from 'lodash/pickBy';
import get from 'lodash/get';

import { external } from '@emobg/web-api-client';
import { createCustomStore } from '@emobg/web-utils';

import { COST_ALLOCATION_TYPE } from '@/constants/costAllocationType.const';
import * as CostAllocationDataModule from '@/stores/CostAllocation/DataModule/CostAllocationDataModule';

export const NAMESPACE = 'BookingCostAllocation';

export const MUTATIONS = {
  setExternalBooking: 'SET_EXTERNAL_BOOKING',
};

export const ACTIONS = {
  fetchBookingCostAllocation: 'FETCH_BOOKING_COST_ALLOCATION',
  fetchExternalBooking: 'FETCH_EXTERNAL_BOOKING',
};

export const store = createCustomStore(() => ({
  namespaced: true,
  modules: {
    [CostAllocationDataModule.NAMESPACE]: CostAllocationDataModule.store,
  },
  state: {
    externalBooking: null,
  },
  mutations: {
    [MUTATIONS.setExternalBooking](state, payload) {
      state.externalBooking = payload;
    },
  },
  actions: {
    async [ACTIONS.fetchBookingCostAllocation]({ commit }, { companyUuid = '', userProfileUuid = '' }) {
      const payload = pickBy({
        filter: COST_ALLOCATION_TYPE.booking,
        user_profile_uuid: userProfileUuid,
      });

      const response = await external.companyCostAllocation.getCostAllocations(companyUuid, payload);

      commit(
        CostAllocationDataModule.MUTATIONS.setCostAllocationList,
        get(response, 'data', []),
      );
    },

    async [ACTIONS.fetchExternalBooking]({ commit }, { companyUuid = '', token = '' }) {
      const response = await external.companyCostAllocation.getExternalBooking(companyUuid, { token });

      commit(MUTATIONS.setExternalBooking, response);
    },
  },
}));
