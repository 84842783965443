import get from 'lodash/get';
import { HTTP_STATUS_CODES } from '@emobg/web-utils';

/**
 * Manually throws an error when `userDetailsResponse` status is 401 or more
 *
 * @param {Object} userDetailsResponse - the response to be evaluated
 *
 * @throws will throw the response's message if status is 401 or more
 */
export const validateUserDetailsResponse = ({ response }) => {
  const status = get(response, 'status');

  if (status > HTTP_STATUS_CODES.badRequest) {
    throw new Error(get(response, 'data.message'));
  }
};

/**
 * Validates if the user is watchlisted by checking the error data
 *
 * @param {Object} error - the error that contains relevant data to validate if user is watchlisted
 * @returns {boolean} returns whether the user is watchlisted or not
 */
export const validateIsUserWatchlisted = error => {
  // User is watchlisted
  const statusCode = get(error, 'response.status');
  const errorInternalCode = get(error, 'response.data.internal_code', error.internal_code);
  const internalCodeWatchlistedUser = '020001';

  return errorInternalCode === internalCodeWatchlistedUser
  && statusCode === HTTP_STATUS_CODES.unauthorized;
};
