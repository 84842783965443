import {
  mapActions,
  mapGetters,
} from 'vuex';

import { NAMESPACE as UserNamespace } from './UserModule';

import {
  ACTIONS,
  NAMESPACE as AccountNamespace,
  GETTERS,
} from './Account/AccountModule';

export const {
  fetchUserAccount,
  cancelUserAccount,
  getUserBadge,
  getUserDrivingLicense,
  getUserPaymentMethods,
  getPersonalInformation,
  getUserAddressCountry,
} = {
  ...mapActions(`${UserNamespace}/${AccountNamespace}`, {
    fetchUserAccount: ACTIONS.fetchUserAccount,
    cancelUserAccount: ACTIONS.cancelUserAccount,
  }),

  ...mapGetters(`${UserNamespace}/${AccountNamespace}`, {
    getUserBadge: GETTERS.getUserBadge,
    getUserDrivingLicense: GETTERS.getUserDrivingLicense,
    getUserPaymentMethods: GETTERS.getUserPaymentMethods,
    getPersonalInformation: GETTERS.getPersonalInformation,
    getUserAddressCountry: GETTERS.getUserAddressCountry,
  }),

};
