import { createCustomStore } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

export const ACTIONS = {
  fetchCountries: 'fetchCountries',
};

export const NAMESPACE = 'Country';

export const CountryStore = createCustomStore(({ runAsyncFlow }) => (
  {
    actions: {
      async [ACTIONS.fetchCountries]({ commit }) {
        await runAsyncFlow(commit, {
          request: external.app.getCountries,
          params: ['v2'],
        });
      },
    },
  }
));
