import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import some from 'lodash/some';
import union from 'lodash/union';

import {
  STORE_REQUEST_STATUS,
  createCustomStore,
  runAsyncFlow,
  withScopes,
} from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';
import {
  REQUIRED_DOCUMENT_STATUS,
  REQUIRED_DOCUMENT_TYPES,
} from '@/constants/userValidation';

const NAMESPACE = 'UserValidations';

const SCOPES = {
  validations: 'validations',
  cities: 'cities',
  requiredDocuments: 'requiredDocuments',
};

const GETTERS = {
  userValidationsByBookingTypeOperator: 'userValidationsByBookingTypeOperator',
  isValidStatusByType: 'isValidStatusByType',
  hasValidationStatus: 'hasValidationStatus',
  requiredDocuments: 'requiredDocuments',
  isRequiredDocumentsLoading: 'isRequiredDocumentsLoading',
  hasRequiredDocumentByStatus: 'hasRequiredDocumentByStatus',
  hasUploadedDrivingLicense: 'hasUploadedDrivingLicense',
  hasCompletedDrivingLicense: 'hasCompletedDrivingLicense',
  hasCompletedBadge: 'hasCompletedBadge',
  areRequiredDocumentsForCorporates: 'areRequiredDocumentsForCorporates',
  areRequiredDocumentsForCustomers: 'areRequiredDocumentsForCustomers',
  citiesFetchedByBookingType: 'citiesFetchedByBookingType',
  validationsWithoutDocument: 'validationsWithoutDocument',
  isCityFetched: 'isCityFetched',
};

const ACTIONS = {
  getUserCityValidations: 'getUserCityValidations',
  fetchUserValidations: 'fetchUserValidations',
  fetchRequiredDocuments: 'fetchRequiredDocuments',
};

const store = createCustomStore(() => withScopes(SCOPES,
  (
    {
      getters: {
        [GETTERS.citiesFetchedByBookingType]: state => bookingType => get(state, `${SCOPES.cities}.data.${bookingType}`, []),
        [GETTERS.userValidationsByBookingTypeOperator]: state => (bookingType, operator) => get(
          state,
          `${SCOPES.validations}.data.${bookingType}.${operator}`,
          [],
        ),
        documentStatus(_state, getters) {
          return (bookingType, operator, documentCode) => {
            const validationsByOperator = getters[GETTERS.userValidationsByBookingTypeOperator](bookingType, operator);
            const userDocument = find(validationsByOperator, ['related_document_type', documentCode]);

            return get(userDocument, 'status');
          };
        },
        [GETTERS.validationsWithoutDocument](_state, getters) {
          return (bookingType, operator) => {
            const validationsByOperator = getters[GETTERS.userValidationsByBookingTypeOperator](bookingType, operator);

            return filter(validationsByOperator, ['related_document_type', null]) || [];
          };
        },
        [GETTERS.isCityFetched]: state => ({ bookingType, cityUuid }) => includes(get(state, `${SCOPES.cities}.data.${bookingType}`, []), cityUuid),
        [GETTERS.isValidStatusByType](_state, getters) {
          return ({ bookingType, csOperatorUuid, type }) => {
            const validationsByOperator = getters[GETTERS.userValidationsByBookingTypeOperator](bookingType, csOperatorUuid);

            return some(validationsByOperator, ['status', type]);
          };
        },
        [GETTERS.hasValidationStatus](_state, getters) {
          return ({ bookingType, operatorUuid }) => !isEmpty(getters[GETTERS.userValidationsByBookingTypeOperator]({
            bookingType,
            operatorUuid,
          }));
        },
        [GETTERS.requiredDocuments](state) {
          return get(state, `${SCOPES.requiredDocuments}.data`) || [];
        },
        [GETTERS.areRequiredDocumentsForCustomers](_state, getters) {
          const drivingLicense = find(getters[GETTERS.requiredDocuments], ['type', REQUIRED_DOCUMENT_TYPES.drivingLicense]);
          const badge = find(getters[GETTERS.requiredDocuments], ['type', REQUIRED_DOCUMENT_TYPES.badge]);

          return Boolean(drivingLicense) || Boolean(badge);
        },
        [GETTERS.areRequiredDocumentsForCorporates](_state, getters) {
          const drivingLicenseReview = find(getters[GETTERS.requiredDocuments], ['type', REQUIRED_DOCUMENT_TYPES.drivingLicenseReview]);
          const restrictiveBadge = find(getters[GETTERS.requiredDocuments], ['type', REQUIRED_DOCUMENT_TYPES.restrictiveBadge]);

          return Boolean(drivingLicenseReview) || Boolean(restrictiveBadge);
        },
        [GETTERS.isRequiredDocumentsLoading]: state => get(state, `${SCOPES.requiredDocuments}.STATUS.${STORE_REQUEST_STATUS.loading}`),
        [GETTERS.hasRequiredDocumentByStatus](state) {
          return ({ type = '', status = '' } = {}) => {
            const data = get(state, `${SCOPES.requiredDocuments}.data`) || [];

            return !!find(data, {
              type,
              status,
            });
          };
        },
        [GETTERS.hasUploadedDrivingLicense](_state, getters) {
          const drivingLicenseCompleted = getters[GETTERS.hasRequiredDocumentByStatus]({
            type: REQUIRED_DOCUMENT_TYPES.drivingLicense,
            status: REQUIRED_DOCUMENT_STATUS.completed,
          });

          const drivingLicenseReviewCompleted = getters[GETTERS.hasRequiredDocumentByStatus]({
            type: REQUIRED_DOCUMENT_TYPES.drivingLicenseReview,
            status: REQUIRED_DOCUMENT_STATUS.completed,
          });

          const drivingLicensePending = getters[GETTERS.hasRequiredDocumentByStatus]({
            type: REQUIRED_DOCUMENT_TYPES.drivingLicense,
            status: REQUIRED_DOCUMENT_STATUS.pending,
          });

          const drivingLicenseReviewPending = getters[GETTERS.hasRequiredDocumentByStatus]({
            type: REQUIRED_DOCUMENT_TYPES.drivingLicenseReview,
            status: REQUIRED_DOCUMENT_STATUS.pending,
          });

          return drivingLicenseCompleted
            || drivingLicensePending
            || drivingLicenseReviewCompleted
            || drivingLicenseReviewPending;
        },
        [GETTERS.hasCompletedBadge](_state, getters) {
          return getters[GETTERS.hasRequiredDocumentByStatus]({
            type: REQUIRED_DOCUMENT_TYPES.badge,
            status: REQUIRED_DOCUMENT_STATUS.completed,
          });
        },
        [GETTERS.hasCompletedDrivingLicense](_state, getters) {
          return getters[GETTERS.hasRequiredDocumentByStatus]({
            type: REQUIRED_DOCUMENT_TYPES.drivingLicense,
            status: REQUIRED_DOCUMENT_STATUS.completed,
          });
        },
      },
      mutations: {
        setOperatorValidations(state, { validations, bookingType }) {
          const validationsByOperator = groupBy(validations, 'cs_operator_uuid');
          state[SCOPES.validations].data[bookingType] = merge(state[SCOPES.validations].data[bookingType], validationsByOperator);
        },
        setCityFetched(state, { cityUuid, bookingType }) {
          state[SCOPES.cities].data[bookingType] = union(state[SCOPES.cities].data[bookingType], [cityUuid]);
        },
      },
      actions: {
        async [ACTIONS.fetchUserValidations]({ commit }, { cityUuid, bookingType }) {
          const query = { booking_type: bookingType };
          const validations = await external.userCompanyUserValidations.getUserValidations(cityUuid, query);
          commit('setOperatorValidations', { validations, bookingType });
          commit('setCityFetched', { cityUuid, bookingType });
        },
        async [ACTIONS.getUserCityValidations]({ getters, dispatch }, { cityUuid, bookingType }) {
          if (!getters.isCityFetched({ cityUuid, bookingType })) {
            await dispatch(ACTIONS.fetchUserValidations, { cityUuid, bookingType });
          }

          return getters[GETTERS.userValidationsByBookingTypeOperator]({ cityUuid, bookingType });
        },
        [ACTIONS.fetchRequiredDocuments]({ commit }, { csOperatorUuid }) {
          return runAsyncFlow(commit, {
            request: external.userCompanyUserValidations.getRequiredDocuments,
            params: [csOperatorUuid],
            scope: SCOPES.requiredDocuments,
          });
        },
      },
    }
  )));

export default {
  store,
  NAMESPACE,
  GETTERS,
  ACTIONS,
  SCOPES,
};
