import get from 'lodash/get';
import { external } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';

import { COST_ALLOCATION_TYPE } from '@/constants/costAllocationType.const';

export const NAMESPACE = 'CompanyCostAllocations';

export const ACTIONS = {
  fetchCompanyProfileCostAllocations: 'fetchCompanyProfileCostAllocations',
};

export const GETTERS = {
  getCompanyProfileCostAllocations: 'getCompanyProfileCostAllocations',
  isFetchCompanyProfileCostAllocationsLoading: 'isFetchCompanyProfileCostAllocationsLoading',
};

export const SCOPES = {
  companyProfileCostAllocations: 'companyProfileCostAllocations',
};

export const store = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, (
  {
    namespaced: true,
    getters: {
      [GETTERS.getCompanyProfileCostAllocations](state) {
        return get(state, `${SCOPES.companyProfileCostAllocations}.data.data`);
      },
      [GETTERS.isFetchCompanyProfileCostAllocationsLoading](state) {
        return get(state, `${SCOPES.companyProfileCostAllocations}.STATUS.LOADING`);
      },
    },
    actions: {
      async [ACTIONS.fetchCompanyProfileCostAllocations]({ commit }, companyUuid) {
        await runAsyncFlow(commit, {
          request: external.companyCostAllocation.getCostAllocations,
          params: [
            companyUuid,
            {
              filter: COST_ALLOCATION_TYPE.profile,
            },
          ],
          scope: SCOPES.companyProfileCostAllocations,
        });
      },
    },
  }
)));
