import get from 'lodash/get';
import map from 'lodash/map';
import trim from 'lodash/trim';
import isArray from 'lodash/isArray';

import { INITIAL_STATUS, setStatus } from '@emobg/web-utils';
import INPUT_TYPES from '@/constants/inputTypes';

export const NAMESPACE = 'COST_ALLOCATION_DATA';

export const INIT_STATE = () => ({
  STATUS: {
    ...INITIAL_STATUS,
  },
  currentCostAllocations: null,
  costAllocationList: null,
});

export const GETTERS = {
  apiPayloadCurrentCostAllocation: 'API_PAYLOAD_CURRENT_COST_ALLOCATION',
};

export const getters = {
  [GETTERS.apiPayloadCurrentCostAllocation](state) {
    if (!isArray(state.currentCostAllocations)) {
      return [];
    }

    const payload = map(state.currentCostAllocations, costAllocation => {
      const parentUuid = get(costAllocation, 'uuid', '');

      const type = get(costAllocation, 'inputType', 'select');

      let value = null;
      switch (type) {
        case INPUT_TYPES.text:
          value = trim(get(costAllocation, 'children.0.code', null));
          break;
        case INPUT_TYPES.select:
          value = get(costAllocation, 'children.0.uuid', null);
          break;
        default:
          break;
      }

      if (!(parentUuid && type && value)) {
        return null;
      }

      return {
        parent_uuid: parentUuid,
        type,
        value,
      };
    })
      .filter(item => item);

    return payload;
  },
};

export const MUTATIONS = {
  setStatus: 'SET_STATUS',
  setCurrentCostAllcation: 'SET_CURRENT_COST_ALLOCATION',
  setCostAllocationList: 'SET_COST_ALLOCATION_LIST',
};

export const mutations = {
  [MUTATIONS.setStatus]: setStatus,
  [MUTATIONS.setCurrentCostAllcation](state, payload) {
    state.currentCostAllocations = [...payload];
  },
  [MUTATIONS.setCostAllocationList](state, payload) {
    state.costAllocationList = [...payload];
  },
};

export const store = {
  state: INIT_STATE,
  mutations,
  getters,
};
