import {
  LOG_TYPE, getValue, isNilValue, logger, toLowercaseString,
} from '@emobg/web-utils';
import size from 'lodash/size';
import includes from 'lodash/includes';

import { allowedThemes } from '@/constants/themes';
import cfg from '@/config';

const DEFAULT_FILE_FORMAT = 'svg';
const DEFAULT_FLAVOR = 'ubeeqo';
const DEFAULT_ROOT_FOLDER = 'assets';
const CURRENT_FLAVOR = getValue(cfg, 'data.flavor', DEFAULT_FLAVOR);

export const isAllowedFlavor = flavor => (includes(allowedThemes, flavor));

/**
 * @function getFileFormat
 * @desc Returns file format from a string otherwise the default file format
 *
 * @param {String} assetKey
 * @example <caption>Example usage of getFileFormat.</caption>
 *  * // returns "gif"
 * getFileFormat('filename.gif')
 * @return {String}
 */
export const getFileFormat = assetKey => (size(assetKey.split('.')) === 1 ? DEFAULT_FILE_FORMAT : assetKey.split('.')[1]);

/**
 * @function getThemedAssetByCategoryAndKey
 * @desc Returns file format from a string otherwise the default file format
 *
 * @param {String} assetCategory - category of the asset (ex: illustrations)
 * @param {String} assetKey      - key of the asset (ex: logo-primary)
 * @param {String} flavor        - (optional) flavor value for theme (ex: ecod/ECOD)
 * @example <caption>Example usage of getAssetByCategoryAndKey.</caption>
 *  * // returns "/assets/ecod/logos/logo-primary.svg"
 * getAssetByCategoryAndKey('logos', 'logo-primary', 'ecod')
 * @return {String}
 */
export const getThemedAssetByCategoryAndKey = (assetCategory, assetKey, flavor = CURRENT_FLAVOR) => {
  if (isNilValue(assetCategory) || isNilValue(assetKey)) {
    logger.message('Impossible to retrieve asset.', LOG_TYPE.warning);
    return null;
  }

  const scope = toLowercaseString(flavor);

  const fileFormat = getFileFormat(assetKey);

  const fileName = assetKey.split('.')[0];

  return `/${DEFAULT_ROOT_FOLDER}/${scope}/${assetCategory}/${fileName}.${fileFormat}`;
};
