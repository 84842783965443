import get from 'lodash/get';
import pick from 'lodash/pick';
import flatMap from 'lodash/flatMap';
import { external } from '@emobg/web-api-client';
import {
  camelCaseKeys,
  createCustomStore,
  withScopes,
} from '@emobg/web-utils';

import { errorNotification } from '@/handlers/errorHandler.const';
export const NAMESPACE = 'LOGBOOK';

export const SCOPES = {
  logbooks: 'logbooks',
  bookingTrips: 'bookingTrips',
};

export const Logbook = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, ({
  mutations: {
    setError(state, error) {
      const errorMessage = get(errorNotification(error), 'text');
      state.bookingTrips.error = errorMessage;
      throw new Error(errorMessage);
    },
  },
  getters: {
    logbooks(state) {
      return get(state, 'logbooks.data.data');
    },
    bookingTrips(state) {
      return get(state, 'bookingTrips.data.data');
    },
    availaibleReasons(state) {
      return camelCaseKeys(
        pick(
          get(state, 'bookingTrips.data'),
          ['availableOvernightReasons', 'availablePurposes'],
        ),
      );
    },
    bookingWayPoints(state, getters) {
      return flatMap(getters.bookingTrips, 'wayPoints');
    },
  },
  actions: {
    async fetchLogbooks({ commit }) {
      await runAsyncFlow(commit, {
        request: external.booking.getLogbookBookings,
        scope: SCOPES.logbooks,
      });
    },
    async fetchLogbooksTrips({ commit }, { bookingUuid }) {
      await runAsyncFlow(commit, {
        request: external.booking.getBookingTrips,
        params: [bookingUuid],
        scope: SCOPES.bookingTrips,
      });
    },
    async mergeLogbookTrips({ commit }, { bookingUuid, trips }) {
      await runAsyncFlow(commit, {
        request: external.booking.putMergeBookingTrips,
        params: [bookingUuid, trips],
      });
    },
    async saveLogbookTrips({ commit }, { bookingUuid, data }) {
      await runAsyncFlow(commit, {
        request: external.booking.putSaveLogbook,
        params: [bookingUuid, data],
      });
    },
  },
})));

