import find from 'lodash/find';
import { external } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import get from 'lodash/get';

const LOCATION_TYPES = {
  work: 'work',
  home: 'home',
  custom: 'custom',
};

export const ACTIONS = {
  fetchFavouriteLocations: 'fetchFavouriteLocations',
  postFavouriteLocation: 'postFavouriteLocation',
  deleteFavouriteLocation: 'deleteFavouriteLocation',
};

export const GETTERS = {
  currentFavouriteLocations: 'currentFavouriteLocations',
};

export const MUTATIONS = {
  setError: 'setError',
};

export const scopes = {
  favouriteLocations: 'favouriteLocations',
};

export const NAMESPACE = 'FAVOURITE_LOCATIONS';

export const FavouriteLocationsModule = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    [MUTATIONS.setError](state, error) {
      const errorMessage = get(error, 'message');
      state.favouriteLocations.error = errorMessage;
      throw new Error(errorMessage);
    },
  },
  getters: {
    [GETTERS.currentFavouriteLocations]: state => ({
      work: find(state.favouriteLocations.data, ['type', LOCATION_TYPES.work]) || {},
      home: find(state.favouriteLocations.data, ['type', LOCATION_TYPES.home]) || {},
      custom: find(state.favouriteLocations.data, ['type', LOCATION_TYPES.custom]) || {},
    }),
  },
  actions: {
    async [ACTIONS.fetchFavouriteLocations]({ commit }, userUuid) {
      await runAsyncFlow(commit, {
        request: external.userCompanyUserFavouriteLocations.getFavouriteLocations,
        params: [userUuid],
        scope: scopes.favouriteLocations,
      });
    },
    async [ACTIONS.postFavouriteLocation]({ commit }, payload) {
      const { userUuid, location } = payload;
      await runAsyncFlow(commit, {
        request: external.userCompanyUserFavouriteLocations.postFavouriteLocation,
        params: [userUuid, location],
      });
    },
    async [ACTIONS.deleteFavouriteLocation]({ commit }, locationUuid) {
      await runAsyncFlow(commit, {
        request: external.userCompanyUserFavouriteLocations.deleteFavouriteLocation,
        params: [locationUuid],
      });
    },
  },
})));
