import { detectBrowser } from '@emobg/web-utils';

const {
  isOpera, isFirefox, isChrome, isSafari,
} = detectBrowser();

export const browserClassname = () => {
  const classname = 'browser';

  if (isOpera) {
    return `${classname}-opera`;
  }

  if (isFirefox) {
    return `${classname}-firefox`;
  }

  if (isChrome) {
    return `${classname}-chrome`;
  }

  if (isSafari) {
    return `${classname}-safari`;
  }

  return `${classname}-not-detected`;
};
