import {
  INITIAL_STATUS, STORE_REQUEST_STATUS, rehydrate, setStatus,
} from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

import filter from 'lodash/filter';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

export const NAMESPACE = 'OperatorLocations';
export const FULL_NAMESPACE = `${NAMESPACE}`;

const initialState = {
  operatorLocations: [],
  STATUS: { ...INITIAL_STATUS },
};

export const MUTATIONS = {
  setOperatorLocations: 'SET_OPERATOR_LOCATIONS',
  setStatus: 'SET_STATUS',
};

export const mutations = {
  [MUTATIONS.setOperatorLocations](state, locations) {
    state.operatorLocations = locations;
  },
  [MUTATIONS.setStatus]: setStatus,
};

export const GETTERS = {
  oneWayLocations: 'oneWayLocations',
  operatorLocations: 'operatorLocations',
};

export const getters = {
  [GETTERS.oneWayLocations](state) {
    return filter(state.operatorLocations, 'one_way_allowed');
  },
  [GETTERS.operatorLocations](state) {
    return get(state, 'operatorLocations', []);
  },
};

export const ACTIONS = {
  fetchOperatorLocations: 'FETCH_OPERATOR_LOCATIONS',
  fetchLocationByOperatorUuid: 'FETCH_LOCATION_BY_OPERATOR_UUID',
};

const actions = {
  async [ACTIONS.fetchOperatorLocations]({ commit }, { operatorUuid, query } = {}) {
    commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loading);
    const storeName = `${NAMESPACE}_${operatorUuid}`;
    const storedData = rehydrate.get(storeName);

    if (storedData) {
      commit(MUTATIONS.setOperatorLocations, cloneDeep(get(storedData[operatorUuid], 'data')));
    }
    try {
      const response = await external.fleetLocations.getLocationsByOperator(operatorUuid, query);
      commit(MUTATIONS.setOperatorLocations, response.data);
      rehydrate.set(storeName, operatorUuid, response);
      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loaded);
    } catch (error) {
      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.error);
    }
  },
  async [ACTIONS.fetchLocationByOperatorUuid]({ commit }, { operatorUuid }) {
    let locations;
    try {
      const response = await external.fleetLocations.getLocationsByOperator(operatorUuid);
      locations = response.data;
    } catch (error) {
      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.error);
    }
    return locations;
  },
};

export const store = {
  namespaced: true,
  state: initialState,
  mutations,
  getters,
  actions,
};

export default store;
